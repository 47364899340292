// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3E%3Cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".carousel[data-v-1fca2329]{height:100%;&[data-v-1fca2329]:hover{.carousel-control-next[data-v-1fca2329],.carousel-control-prev[data-v-1fca2329]{opacity:100%}}}.carousel-inner[data-v-1fca2329]{height:100%}.carousel-control-next[data-v-1fca2329],.carousel-control-prev[data-v-1fca2329]{transition:.2s;opacity:0}.black-arrows .carousel-control-next-icon[data-v-1fca2329]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")!important}.black-arrows .carousel-control-prev-icon[data-v-1fca2329]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")!important}.backgroundArrows[data-v-1fca2329]{background:rgba(0,0,0,.5)}@media screen and (max-width:1000px){.carousel-control-next[data-v-1fca2329],.carousel-control-prev[data-v-1fca2329]{opacity:1}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
