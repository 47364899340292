<script setup>
import {onMounted, ref} from "vue";
import {DBService, post} from "../../services/apiReq2";

const data = ref([])
onMounted(async () => {
  let response = await post("/read",{
    schema: "Fortaleza.db",
    table:"formularios_sorteo",
    limit: 999,
    page: 1,
  }, DBService);

  data.value = response.data.rows;
  console.log(data)
});

</script>



<template>
  <div class="table-container">
    <table class="data-table">
      <thead>
      <tr>
        <th>#</th>
        <th>Nombre</th>
        <th>Apellido</th>
        <th>Email</th>
        <th>Telefono</th>
        <th>DNI</th>
        <th>Codigo</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <td>{{ item.id }}</td>
        <td>{{ item.nombre }}</td>
        <td>{{ item.apellido }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.telefono }}</td>
        <td>{{ item.dni }}</td>
        <td>{{ item.codigo }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
table {
  margin-top: 6em !important;
}
.table-container {
  width: 90%;
  max-width: 800px;
  margin: 5em auto;
  overflow-x: auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin: auto;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.data-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.data-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.data-table tr:hover {
  background-color: #f1f1f1;
}
</style>
