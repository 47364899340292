<script setup>
import { imageHandler } from "../../services/errors";
import { backendUrl, post } from "../../services/apiReq";
import { useStore } from "vuex";
import { Swiper } from 'swiper';
import { Autoplay } from "swiper/modules";
import 'swiper/swiper-bundle.css';
import { onMounted, reactive, ref } from "vue";
import { specialDate } from "@/utils/utils";

//Swiper.use(Autoplay);

const props = defineProps(["item"]);
const store = useStore();

const cartCounter = ref(1);
const carousel = reactive({});

const sDate = ref();

const calculatePrice = (product) => {
  if (product?.ofertas) {
    const price = product?.precio;
    const discount = product?.ofertas?.descuento;

    const dPrice = price - ((discount * price) / 100);
    return dPrice?.toFixed(2);
  }
  else return product?.precio;
}
const detail = (item) => {
  location.href = `/itemdetail?producto=${item?.codigo}`
}
const addToCart = async (producto) => {
  let res = await post("/carrito", {
    user_id: store?.getters?.getUser?.id,
    cantidad: cartCounter.value,
    producto_codigo: producto?.codigo,
    observacion: ''
  });
};
async function decrement() {
  if (cartCounter.value <= 1) return;
  cartCounter.value--;
}

async function increment() {
  cartCounter.value++;
}

onMounted(() => {
  getSpecialDate()
  carousel.value = new Swiper('.swiper-container', {
    slidesPerView: 'auto',
    autoplay: {
      delay: 1300,
      disableOnInteraction: false,
    },
  });
})

const getSpecialDate = (() => {
  sDate.value = specialDate()
})
</script>

<template>
  <a class="card_a" :href="`/itemdetail?producto=${item?.codigo}`" target="_blank">
    <div class="card" fade :class="sDate?.name">
      <!--<div class="christmas-decoration" v-if="sDate?.name === 'christmas'">
        <img v-if="sDate?.name === 'christmas'" src="/images/monio.webp" alt="">
        <img v-if="sDate?.name === 'valentines'" src="/images/monio.webp" alt="">
        <img v-else src="/images/monio.webp" alt="">
      </div>-->
      <div class="no-stock-banner" v-if="item?.stock" shadow slideRight>
        <p>SIN STOCK</p>
      </div>
      <div class="no-stock-banner" v-if="item?.ofertas && !item?.stock" shadow slideRight>
        <p>{{ item?.ofertas?.descuento }}% OFF</p>
      </div>
      <!--<div class="deco-container">
        <img class="card-decoration" src="/images/navidad.png" />
      </div>-->
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(image, key) in item?.images" :key>
            <img :src="`${backendUrl}/files/2/${image}`" alt="La Fortaleza Producto" />
            <div class="no-stock-banner frio" v-if="item?.frio" shadow slideRight>
              <p>Necesita cadena de frio</p>
            </div>

          </div>
        </div>
      </div>
      <!--
    <div class="card__img">
      <img :title="item ? item.nombre : ''" :src="`${backendUrl}/files/2/${item?.codigo?.trim()}.png`" @error="imageHandler($event)" alt="Productos La Fortaleza">
    </div>
    -->
      <div class="card__content">
        <h4 class="card-title" nopointer>{{ item?.nombre }}</h4>
        <small id="marca" class="marca">{{ item?.marca }}</small> <br>
        <div class="addCart" v-if="store?.getters?.getUser?.id && !item?.stock" flex-center gapped>
          <ion-icon @click.prevent="addToCart(item)" class="icon" name="cart-outline"
            title="Añadir al carrito"></ion-icon>
          <div class="cart-counter" flex-center gapped>
            <a @click.prevent="decrement" flex-center radius-border>-</a>
            <p>{{ cartCounter }}</p>
            <a @click.prevent="increment" flex-center radius-border>+</a>
          </div>
        </div>
        <p class="card-price" v-if="store?.getters?.getUser?.id">${{ calculatePrice(item) }}</p>
        <div class="orange" v-else>
          <a class="login-to-see" href="/login"><small>Iniciar sesión para ver detalles</small></a>
        </div>
      </div>
    </div>
  </a>
</template>


<style scoped lang="scss">
.cart-counter {

  a {
    background: var(--valentines);
    color: white;
    height: 30px;
    width: 30px;
    transition: var(--duration);

    &:hover {
      border-radius: 8px;
    }
  }
}

.frio {
  top: unset !important;
  right: unset !important;
  bottom: 0;

}

.swiper-container {
  width: 100%;
  height: 100%;
  //background: var(--special-color);
  background: white;
  .swiper-wrapper {
    width: 100%;
    height: 100%;

    .swiper-slide {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 306px;

        max-height: 306px;
      }
    }
  }
}

.orange {
  color: black;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 1em;
}

.card_a {
  height: 460px !important;
  margin-top: 5em;
  max-width: 30ch;
  display: block;
  width: 100%;
}

.card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 30ch;
  height: 100%;
  border-radius: .5rem;
  overflow: hidden;
  transition: transform 500ms ease;
  position: relative;
}

.card:hover,
.card:focus-within {
  transform: scale(1.02);
}

.card-price {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1em;
  color: black !important;
}

.card__img {
  margin: 0px;
  padding: 0px;
  height: 160px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.card__img img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}

.card-title {
  text-decoration: none;
}

.card-title:hover {
  text-decoration: underline !important;
}

.card__content {
  background: white;
  padding: 1.2rem .9rem;
  color: black !important;;
}

.card-body__content {
  display: flex;
  justify-content: space-between;
  padding: 28px 0;
}

.card-price {
  color: #0db60a;
  font-weight: bold;
}

.card__btn {
  cursor: pointer;
  display: inline;
  color: #fff;
  background-color: #666;
  margin-top: 10px;
  padding: 0.5em 1.5em;
  border-radius: 1em;
  transition: .2s;
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
}

.card__btn:hover,
.card__btn:focus {
  background-color: #F8853E;
}

.no-stock-banner {
  position: absolute;
  right: 0;
  top: 0;
  width: fit-content;
  background: #da3a00;
  padding: .2em 1em .2em .5em;
  z-index: 101;

  p {
    width: 100%;
    text-align: right;
    color: white;
  }
}

.deco-container {
  position: absolute;
  z-index: 100;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  width: max-content;
  height: auto;
}

.card-decoration {
  width: 260px;
  /* Mantén el ancho según el contenedor */
  height: 270px;
  /* Ajusta la altura automáticamente */
}

.addCart {
  width: fit-content;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 1em;

  .icon {
    width: 20px;
    height: 20px;
    padding: .5em;
    border-radius: 5px;
    color: white;
    background: var(--valentines);
    transition: var(--duration);

    &:hover {
      border-radius: 8px;
    }
  }
}

.marca {
  display: block;
  margin-bottom: 2em;
  color: black;
}

.login-to-see {
  //columns: white;
  font-size: 1.04rem;
}

.christmas {
  color: white;

  a,
  small {
    color: white;
  }
}

.christmas-decoration {
  position: absolute;
  top: 6px;
  left: 8px;
  z-index: 102;

  transform: rotate(-43deg);

  img {
    width: 64px;
  }
}

@media only screen and (max-width: 670px) {
  .card_a {
    height: auto !important;
    margin-top: 2em;
    max-width: 300px;
  }

  .card {
    max-width: 300px;
  }

  .deco-container {
    display: none;
    position: absolute;
    z-index: 999;
    /* Asegúrate de que esté por encima del resto del contenido */
    top: 0;
    /* Siempre en la parte superior */
    bottom: 86%;
    left: 50%;
    /* Centrado horizontalmente */
    transform: translateX(-50%);
    /* Ajuste para centrar */
  }

  .card-decoration {
    width: 200px;
    height: 200px;
  }

  .card__img img {
    width: 100%;
    height: auto;
  }

  .card-title {
    font-size: 16px;
  }

  .card-body__content {
    padding: 16px 0 30px 0;
  }

  .card-price {
    color: black !important;
    margin: 0.5em;
  }

  .addCart {
    margin: 0.5em;
  }

  .orange {
    margin: 0.5em;
  }

  .addCart .icon {
    width: 16px;
    height: 16px;
  }
}

@media only screen and (min-width: 560px) and (max-width: 670px) {
  .card_a {
    height: auto !important;
    margin-top: 2em;
    max-width: 300px;
    margin-left: 0;
    margin-right: 0;
  }

  .card {
    max-width: 300px;
  }

  .card__img img {
    width: 100%;
    height: auto;
  }

  .card-title {
    font-size: 16px;
  }

  .card__content {
    margin-top: 48px;
    padding: 1.8rem .8rem;
  }

  .card-body__content {
    padding: 16px 0 30px 0;
  }

  .card-price {
    margin: 0.5em;
  }

  .addCart {
    margin: 0.5em;
  }

  .orange {
    margin: 0.5em;
  }

  .addCart .icon {
    width: 16px;
    height: 16px;
  }
}

@media only screen and (max-width: 400px) {
  .card_a {
    height: auto !important;
    margin-top: 1em;
    max-width: 300px;
  }

  .card {
    padding: .5rem 0 0;
    max-width: 300px;
  }

  .card__img img {
    width: 100%;
    height: auto;
  }

  .card-title {
    font-size: 14px;
  }

  .card__content {
    margin-top: 15px;
    padding: 1rem 0.5rem;
  }

  .card-body__content {
    padding: 16px 0 20px 0;
  }

  .card-price,
  .addCart {
    margin: 0.5em;
  }

  .orange {
    margin: 0.5em;
  }

  .addCart .icon {
    width: 14px;
    height: 14px;
  }
}
</style>
