<script setup>
import Book from "@/components/UI/Book.vue"
import TimeTable from "@/components/UI/TimeTable.vue";
</script>

<script>
import Helmet from '../components/Helmet.vue';
import CommonSection from '../components/HeroSection.vue';
import HeroSection from "@/components/HeroSection.vue";
import Carousel from '@/components/Carousel.vue';
import FAQ from '@/components/FAQ.vue';

export default {
  components: {
    Helmet,
    HeroSection,
    Carousel,
    FAQ
  },
  data() {
    return {
      images: [
        { src: "/images/logo-decor.jpg", alt: "arcor logo", link: "/tienda?search=true&marca=decor" },
        { src: "/images/mantecol-logo.jpg", alt: "mantecol logo", link: "/tienda?search=true&marca=mantecol" },
        { src: "/images/logo-lodiser.png", alt: "lodiser logo", link: "/tienda?search=true&marca=lodiser" },
        { src: "/images/arcor-logo.jpg", alt: "arcor logo", link: "/tienda?search=true&marca=arcor" },
        { src: "/images/ledevit-logo.jpg", alt: "ledevit logo", link: "/tienda?search=true&marca=ledevit" },
        { src: "/images/logo-argenfrut.png", alt: "argenfrut logo", link: "/tienda?search=true&marca=argenfrut" },
        { src: "/images/logo-ballina.png", alt: "ballina logo", link: "/tienda?search=true&marca=ballina" },
        { src: "/images/logo-cañuelas.jpg", alt: "cañuelas logo", link: "/tienda?search=true&marca=cañuelas" },
        { src: "/images/logo-chocolart.png", alt: "chocolart", link: "/tienda?search=true&marca=chocolart" },
        { src: "/images/logo-cooper.png", alt: "cooper logo", link: "tienda?search=true&marca=cooper" },
        { src: "/images/logo-decormagic.jpg", alt: "decormagic logo", link: "tienda?search=true&marca=decormagic" },
        { src: "/images/logo-dewey.png", alt: "dewey logo", link: "tienda?search=true&marca=dewey" },
        { src: "/images/logo-doslembas.png", alt: "doslembas logo", link: "tienda?search=true&marca=doslembas" },
        { src: "/images/logo-gelbrix.jpg", alt: "gelbrix logo", link: "tienda?search=true&marca=gelbrix" },
        { src: "/images/logo-labotica.png", alt: "labotica logo", link: "tienda?search=true&marca=labotica" },
        { src: "/images/logo-mapsa.png", alt: "mapsa logo", link: "tienda?search=true&marca=mapsa" },
        { src: "/images/logo-milkey.png", alt: "milkey logo", link: "tienda?search=true&marca=milkey" },
        { src: "/images/logo-nutella.png", alt: "nutella logo", link: "tienda?search=true&marca=nutella" },
        { src: "/images/logo-parpen.png", alt: "parpen logo", link: "tienda?search=true&marca=parpen" },
        { src: "/images/logo-pastelar.jpg", alt: "pastelar logo", link: "tienda?search=true&marca=pastelar" },
        { src: "/images/logo-telaflex.jpg", alt: "telaflex logo", link: "tienda?search=true&marca=telaflex" },
        { src: "/images/logo-topclass.png", alt: "topclass logo", link: "tienda?search=true&marca=topclass" },
        { src: "/images/logo-vacalin.jpg", alt: "vacalin logo", link: "tienda?search=true&marca=vacalin" },
        { src: "/images/logo-wilton.jpg", alt: "wilton logo", link: "tienda?search=true&marca=wilton" },
        { src: "/images/logo-fincaellazo.jpg", alt: "finca el lazo logo", link: "tienda?search=true&marca=fincaellazo" },
        { src: "/images/logo-barifrucor.png", alt: "bari frucor", link: "tienda?search=true&marca=barifrucor" },
        { src: "/images/logo-labanda.png", alt: "la banda", link: "tienda?search=true&marca=labanda" },
        { src: "/images/logo-ravana.jpg", alt: "ravana", link: "tienda?search=true&marca=ravana" },
        { src: "/images/logo-fleibor.png", alt: "fleibor", link: "tienda?search=true&marca=fleibor" },
      ],
    };
  },
  methods: {
    handleButtonClick(direction) {
      const imageList = this.$el.querySelector(".slider-container .image-grid");
      const scrollAmount = imageList.clientWidth * direction;
      imageList.scrollTo({
        left: imageList.scrollLeft + scrollAmount,
        behavior: "smooth"
      });
    },
    GoTo(link) {
      window.location.href = link;
    }
  }
};
</script>


<template>
  <div class="home">
    <Helmet :title="'Inicio'" />
    <HeroSection title="La Fortaleza Inicio" />
     <!--<Book /> -->

    <!--<div>
      <TimeTable />
    </div>
    -->

    <div class="carousel-container">
      <h2 class="location-p discounts">Ofertas</h2>
      <Carousel />
    </div>

    <div class="container">
      <div class="brands-text-container">
        <h2 class="brands">Marcas</h2>
        <p class="brands-p">Encontrá todas las marcas que necesitas con nosotros</p>
      </div>
      <div class="slider-container">
        <button id="previous-slide" class="slide-button material-symbols-rounded" @click="handleButtonClick(-1)">
          < </button>
            <ul class="image-grid">
              <li v-for="(image, index) in images" :key="index">
                <img class='image-item' :src="image.src" :alt="image.alt" @click="GoTo(image?.link)"
                  title="Marcas La Fortaleza" />
              </li>
            </ul>
            <button id="next-slide" class="slide-button material-symbols-rounded" @click="handleButtonClick(1)">
              {{ ">" }}
            </button>
      </div>

    </div>


    <div class="location-container">
      <h2 class="location-p">Ubicación</h2>
      <div class="ui-card">
        <img src="../../public/images/local-fortaleza1.jpg" title="La Fortaleza Varela" alt="La Fortaleza Varela" />
        <div class="description__card">
          <h3 class="ubi-title">La Fortaleza Varela</h3>
          <p>Av. Eva Perón 4276 - Florencio Varela</p>
          <a href="https://www.google.com/maps/contrib/107516556349392699117/place/ChIJe4f_cYApo5URRcjSZJ5iKYE/@-34.8200254,-58.2794405,17z/data=!4m6!1m5!8m4!1e2!2s107516556349392699117!3m1!1e1?entry=ttu"
            target="_blank">Encontranos</a>
        </div>
      </div>
    </div>
    <div class="FAQ-wrapper">
      <FAQ />
    </div>

    <div class='button-container'>
      <a href="https://api.whatsapp.com/send?phone=541128347514&text=&source=&data=&app_absent=" target='blank'>
        <img className='btn-wsp' src="../../public/images/whatsapp-sticky.png" title="Contáctanos por WhatsApp"
          alt="Clickea aquí para contactarnos" />
      </a>
    </div>
  </div>
</template>


<style scoped>
.home {
  margin-top: 10em;
}

.image-container {
  background: red;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.location-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.location-p {
  text-transform: uppercase;
  font-family: italic;
  font-size: 28px;
  margin: 45px;
}

.discounts {
  color: #ff6200;
}

.ui-card {
  padding: 0px;
  height: auto;
  width: auto;
  background: radial-gradient(#111 50%, #000 100%);
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  box-shadow: #e5e5e5 4px 5px 5px 4px;
}

.ui-card img {
  transform: scale(1.1);
  transition: all 0.3s ease-out;
  width: 310px;

}

.ui-card:hover img {
  transform: scale(1.1) translateY(-5px);
  opacity: 0.3;
}

.description__card {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 15px;
  text-align: center;
  color: #fff;
  font-size: 18px;
}

.description__card .ubi-title {
  font-weight: 700;
  font-size: 25px;
  margin: 0 0 15px;
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.3s ease-out;
}

.description__card p {
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.3s ease-out 0.2s;
  padding: 10px 0;
}

.description__card a {
  color: #fff;
  background: #F8853E;
  display: inline-block;
  padding: 10px 25px;
  text-decoration: none;
  border-radius: 5px;
  transform: translateY(30px);
  opacity: 0;
  transition: all 0.3s ease-out 0.4s;
}

.description__card a:hover {
  background: #b46f00;
}

.ui-card:hover .description__card h3 {
  opacity: 1;
  transform: translateY(0);
}

.ui-card:hover .description__card p {
  opacity: 1;
  transform: translateY(0);
}

.ui-card:hover .description__card a {
  opacity: 1;
  transform: translateY(0);
}


/****************************** Brands Section ************************************/

.container {
  display: flex;
  flex-direction: column;
  padding: 100px 100px 0px 100px !important;
  width: 90%;
  margin: auto;
}

.brands-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.brands {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-family: italic;
  font-size: 28px;
  margin-bottom: 20px;
}

.brands-p {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 30px;
}

.slider-container {
  position: relative;
}

.slider-container .slide-button {
  position: absolute;
  top: 50%;
  outline: none;
  border: none;
  height: 50px;
  width: 50px;
  z-index: 5;
  color: #fff;
  background: #e5562f;
  border: 2px solid #ccc;
  display: flex;
  cursor: pointer;
  font-size: 2.2rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: translateY(-50%);
}

.slider-container .slide-button:hover {
  background: #ff6a00;
}

.slider-container .slide-button#previous-slide {
  left: -20px;
}

.slider-container .slide-button#next-slide {
  right: -20px;
}

.slider-container .image-grid {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  gap: 18px;
  font-size: 0;
  list-style: none;
  margin-bottom: 30px;
  overflow-x: auto;
  scrollbar-width: none;
}

.slider-container .image-grid::-webkit-scrollbar {
  display: none;
}

.slider-container .image-grid .image-item {
  width: 240px;
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.container .slider-scrollbar {
  height: 24px;
  width: 100%;
  display: flex;
  align-items: center;
}

.slider-scrollbar .scrollbar-track {
  background: #ccc;
  width: 100%;
  height: 2px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: relative;
}

.slider-scrollbar:hover .scrollbar-track {
  height: 4px;
}

.slider-scrollbar .scrollbar-thumb {
  position: absolute;
  background: #000;
  top: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  cursor: grab;
  border-radius: inherit;
}


.slider-scrollbar .scrollbar-thumb:active {
  cursor: grabbing;
  height: 8px;
  top: -2px;
}

.slider-scrollbar .scrollbar-thumb::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -10px;
  bottom: -10px;
}

/* SLIDERS */

.slider {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.carousel img {
  width: 100%;
  height: auto;
}

.caption {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  border-radius: 5px;
}

/* FAQ */

.FAQ-wrapper {
  display: flex;
  justify-content: center;
}

/* WhatsApp Btn */

.button-container {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px;

  pointer-events: none;
  z-index: 300;
}

.btn-wsp {
  z-index: 1001;
  cursor: pointer;
  height: 70px;
  width: 70px;
  pointer-events: all;
}


@media only screen and (max-width: 1023px) {

  .location-p,
  .brands {
    font-size: 24px;
  }

  .brands {
    margin-top: 28px;
  }

  .brands-p {
    font-size: 16px;
  }

  .container {
    padding: 10px 0 !important;
  }

  .slider-container .slide-button {
    display: block !important;
    width: 40px;
    height: 40px;
    font-size: 2rem;
    line-height: 20px;
  }

  .slider-container .image-grid {
    gap: 10px;
    margin-bottom: 15px;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    /* Enables smooth scrolling on iOS */
    display: flex;
  }

  .slider-container .image-grid .image-item {
    width: 180px;
    height: 180px;
    scroll-snap-align: start;
    flex-shrink: 0;
  }

  .slider-scrollbar .scrollbar-thumb {
    width: 20%;
  }

  .button-container {
    margin-right: 0px;
  }

  .btn-wsp {
    height: 45px;
    width: 45px;
  }
}
</style>