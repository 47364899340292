<script setup>
import {defineProps, onMounted, ref} from "vue";

const props = defineProps(["mobile", "tablet", "pc", "pclarge", "class", "id"]);
const image = ref('');
onMounted(() => {
  const screenWidth = window.innerWidth;
  if (screenWidth <= 450) {
    image.value = props["mobile"];
  }
  else if (screenWidth <= 900) {
    image.value = props["tablet"];
  }
  else if (screenWidth <= 1400) {
    image.value = props["pc"];
  }
  else {
    image.value = props["pclarge"];
  }
})
</script>
<template>
  <img :class :id :src="image">
</template>