import axios from "axios";
import { show } from "./notification";

export const site_name = "Fortaleza.db";
export const backendUrl = "https://backend.digitalpower.ar";

export const paymentUrl = "https://payment.digitalpower.ar";

export const databaseUrl = "https://database.digitalpower.ar";
export const authUrl = "https://auth.digitalpower.ar";
//export const authUrl = "http://localhost:3013";
//export const databaseUrl = "http://localhost:3014";

const Service = axios.create({
    baseURL: `${backendUrl}/api`,
});
export const DBService = axios.create({
    baseURL: `${databaseUrl}/api`,
});
export const AuthService = axios.create({
    baseURL: `${authUrl}/`,
});
export const PaymentService = axios.create({
    baseURL: `${paymentUrl}/`,
});

let user = { token: null, id: null, site_name: null };
if (localStorage.getItem("user") !== "undefined") user = JSON.parse(localStorage?.getItem("user"))
if (localStorage.getItem("user") !== "undefined")
    user = JSON.parse(localStorage.getItem("user"));


let config = {
    headers: {
        Authorization: `Bearer ${user?.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
    },
};
export const post = (path, body, service = Service) => {
    if (!user) {
        user = JSON.parse(localStorage.getItem("user"));
        config = {
            headers: {
                Authorization: `Bearer ${user?.token}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };
    }
    if (!body) {
        body = {};
    }
    if (!body?.site_name) body.site_name = site_name ?? user?.site_name;
    if (!body?.schema) body.schema = site_name ?? user?.site_name;
    if (user?.id) body.user_id = user?.id;

    return new Promise(async (resolve) => {
        service
            .post(path, body, config)
            .then((response) => {
                let message = response?.message ?? response.data?.message;
                let status = response?.status ?? response.data?.status;

                if (response.data?.data) response = response?.data;
                if (message) {
                    show(message);
                }

                setTimeout(() => {
                    if (response?.data?.redirect) {
                        location.href = response?.data?.redirect;
                    }
                }, 1000);

                if (status === 401) {
                    location.href = "/admin";
                }
                resolve(response);
            })
            .catch((err) => {
                console.log(err)
                if (err?.response?.status === 401) {
                    //localStorage.removeItem("dp_user");
                    //location.href = "/#/login";
                }
                if (err?.response?.data?.message) {
                    show(err?.response?.data?.message);
                } else
                    show("Ha ocurrido un error, intente mas tarde");
            })
            .finally(() => {
            });
    });
};
export const files = (path, body) => {
    if (body?.append) {
        body.append("site_name", user?.site_name);
        if (user?.id) body.append("user_id", user?.id);

    } else {
        body.site_name = user?.site_name;
        if (user?.id) body.user_id = user?.id;

    }

    return new Promise((resolve) => {
        Service
            .post(path, body, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${user?.token}`,
                    Accept: "application/json",
                },
            })
            .then((response) => {
                if (response.data?.message) {
                    show(response.data.message);
                }
                setTimeout(() => {
                    if (response?.data?.data?.redirect)
                        location.href = response?.data?.data?.redirect;
                }, 1000);

                if (response.data?.status === 401) {
                    location.href = "/admin";
                }

                resolve(response.data);
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    location.href = "/#/login";
                }
                show("Ha ocurrido un error, intente mas tarde");
            })
            .finally(() => {
            });
    });
};
