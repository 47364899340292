<script setup>
import ProductCard from '../components/ProductCardSpecial.vue';
import Bulbs from '@/components/UI/Bulbs.vue';

import {onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {specialDate} from '@/utils/utils';

const store = useStore();
const categorias = ref([]);
const params = ref([]);

const products = ref(null);
const showProducts = ref([]);

const currentPage = ref(1);
const lastPage = ref(1);

const categoria = ref(0);
const orderDir = ref("ASC");
const nombre = ref("");
const codigo = ref("");
const sDate = ref();
const route = useRoute();
const audio = ref();

const audioModal = ref(true);

const router = useRouter()

onMounted(async () => {
  var source = "/audio/christmas.m4a";
  audio.value = document.createElement("audio");
  audio.value.src = source;


  const specialFigure = document.getElementById("special-figure");
  specialFigure.style.display = "none";

  setInterval(() => {
    specialFigure.style.display = "flex";
    setTimeout(() => {
      specialFigure.style.display = "none";
    }, 2000);
  }, 20000);

  getSpecialDate();
  let res = await store.dispatch("get", {
    tableName: "categorias",
    perPage: 100,
    page: 1
  })

  categorias.value = res?.data?.data?.map((x) => {
    x.categoria = x?.categoria?.split("\r\n")?.[0]?.toLowerCase();
    x.categoria = x?.categoria?.charAt(0).toUpperCase() + x?.categoria?.slice(1);
    return x;
  }).sort((a, b) => {
    if (a.categoria < b.categoria) return -1;
    if (a.categoria > b.categoria) return 1;
    return 0;
  });

  if (route.query?.search) {
    categoria.value = route.query?.categoria;
    nombre.value = route.query?.nombre;
    codigo.value = route.query?.codigo;
    orderDir.value = route.query?.dir ?? "ASC";
  }
  await get();
})

const get = async () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });


  //showProducts.value = [];
  products.value = await store.dispatch("getProductsSpecial", {
    page: currentPage.value,
    categoria: (categoria.value && categoria.value != 0) ? categoria.value : null,
    nombre: nombre.value ?? null,
    codigo: codigo.value ?? null,
    order: "nombre",
    direction: orderDir.value
  })

  lastPage.value = products.value?.last_page;
  showProducts.value = products.value?.data;
};

const previous = async () => {

  if (currentPage.value <= 1) return;
  console.log('esta es la pagina', currentPage.value);
  currentPage.value--;
  await get();
}

const next = async () => {
  if (currentPage.value >= lastPage.value) return;
  console.log('esta es la pagina', lastPage.value);
  currentPage.value++;
  await get();
}

const handleChange = () => {
  let path = "?search=true";
  if (categoria.value && categoria.value !== 0) path += `&categoria=${categoria.value}`;
  else path = "?search=true";

  if (nombre.value) path += `&nombre=${nombre.value}`;
  if (orderDir.value) path += `&dir=${orderDir.value}`;
  router.push(path);
  get()
}

const getSpecialDate = (() => {
  sDate.value = specialDate()
})

const playSpecialAudio = () => {
  /*audio.value.play();
  audio.value.volume = 0.2;*/
  audioModal.value = false;
}
</script>


<template>
  <div class="special-overlay" flex flex-center v-if="audioModal">
    <div :class="sDate?.name" radius-border slide>
      <h1>¡Hola!</h1>
      <p>¡Desde La Fortaleza queremos desearte un <b>Feliz San Valentin</b>!
        <br>
        Por eso te trajimos los mejores productos para que disfrutes con tus seres queridos.
      </p>
      <a radius-border @click="playSpecialAudio">Cliqueá para ver los productos</a>
    </div>
  </div>
  <div class="special-figure">
    <img v-if="sDate?.name === 'christmas'" src="/images/papanoel.png" alt="" id="special-figure">
    <img v-else src="/images/cupido.png" alt="" id="special-figure">
  </div>
  <div :class="sDate?.name + '-body'">
    <!--<div v-if="sDate?.name === 'christmas'">
          <div class="papanoel">
            &lt;!&ndash; <img src="/images/papanoel.png"> &ndash;&gt;
          </div>
        </div>-->
  </div>

  <body class='body-shop'>
  <div class="body-shop-container">
    <div class="bulbs-c" v-if="sDate?.name === 'christmas'">
      <Bulbs/>
    </div>
    <div class='filter-container'>
      <div class="filter__widget">
        <select @change="handleChange" v-model="categoria"
                aria-label="Orden por Categoria La Fortaleza Distribuidora">
          <option value="0">Filtrar por Categoria</option>
          <option v-for="(categoria, key) in categorias" :key="key" :value="categoria?.categoria">{{
              categoria?.categoria
            }}
          </option>
        </select>
      </div>
      <div class="filter__widget">
        <select @change="handleChange" v-model="orderDir" aria-label="Orden alfabético La Fortaleza Distribuidora">
          <option value="">Filtrar alfabéticamente</option>
          <option value="ASC">Filtrar A-Z</option>
          <option value="DESC">Filtrar Z-A</option>
        </select>
      </div>
    </div>

    <div class="search__box">
      <form @submit.prevent="handleChange">
        <input v-model="nombre" class='search-input' type="text" placeholder='Buscar...'/>
        <span class='search-icon'>
            <ion-icon name="search-outline"></ion-icon>
          </span>
      </form>
    </div>
    <div class="pagination" flex gapped>
      <a class="pagination-button" radius-border @click="previous" aria-label="Página Anterior"
         :class="sDate?.name">Anterior</a>
      <p class="pag-text" flex flex-center>Pág: {{ currentPage }} de {{ lastPage }}</p>
      <a class="pagination-button" :class="sDate?.name" radius-border @click="next"
         aria-label="Página Siguiente">Siguiente</a>
    </div>

    <div class='cards-container'>
      <ProductCard v-for="(product, key) in showProducts" :key="key" :item="product"/>
    </div>

    <div class="pagination" flex gapped>
      <a :class="sDate?.name" class="pagination-button" radius-border @click="previous"
         aria-label="Página Anterior">Anterior</a>
      <p class="pag-text" flex flex-center>Pág: {{ currentPage }} de {{ lastPage }}</p>
      <a :class="sDate?.name" class="pagination-button" radius-border @click="next"
         aria-label="Página Siguiente">Siguiente</a>
    </div>

    <div class='button-container' v-if="showProducts?.length > 0">
      <a href="https://api.whatsapp.com/send?phone=541128347514&text=&source=&data=&app_absent=" target='blank'>
        <img class='btn-wsp' src="../../public/images/whatsapp-sticky.png" title="Contáctanos por WhatsApp"
             alt="Clickea aquí para contactarnos"/>
      </a>
    </div>
  </div>
  </body>
</template>


<style scoped lang="scss">
select {
  margin: 0 !important;
  margin-right: 5px !important;

  //border: none !important;
}

input {

}

.special-figure {
  pointer-events: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 100px;
  z-index: 200;

  & > img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100px;
    transform: translate(-100%);
    animation: santa 2s ease-in-out infinite;
  }
}

.special-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.4);

  & > .christmas, &>.valentines {
    width: 90%;
    max-width: 650px;
    min-height: 250px;
    z-index: 2001;
    padding: 2em;
    margin-top: 5em;

    a {
      display: flex;
      cursor: pointer;
      padding: .5em 1em;
      margin-top: 1em;
      background: var(--valentines);
      width: fit-content;
    }
  }
}

.special-overlay > .valentines > a {
  background: var(--valentines);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.55);
}

.christmas-body, .valentines-body {
  pointer-events: none !important;
}

.christmas-body, .valentines-body {
  &::before {
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
    position: fixed;
    background: radial-gradient(4px 4px at 2% 10%, var(--valentines), transparent),
    radial-gradient(4px 4px at 10% 50%, red, transparent),
    radial-gradient(4px 4px at 15% 30%, red, transparent),
    radial-gradient(4px 4px at 20% 60%, red, transparent),
    radial-gradient(4px 4px at 25% 80%, red, transparent),
    radial-gradient(4px 4px at 30% 40%, red, transparent),
    radial-gradient(4px 4px at 35% 10%, red, transparent),
    radial-gradient(4px 4px at 40% 70%, red, transparent),
    radial-gradient(4px 4px at 45% 20%, red, transparent),
    radial-gradient(4px 4px at 50% 90%, red, transparent),
    radial-gradient(4px 4px at 55% 50%, red, transparent),
    radial-gradient(4px 4px at 60% 30%, red, transparent),
    radial-gradient(4px 4px at 65% 60%, red, transparent),
    radial-gradient(4px 4px at 70% 80%, red, transparent),
    radial-gradient(4px 4px at 75% 40%, red, transparent),
    radial-gradient(4px 4px at 80% 10%, red, transparent),
    radial-gradient(4px 4px at 85% 70%, red, transparent),
    radial-gradient(4px 4px at 90% 50%, red, transparent),
    radial-gradient(4px 4px at 95% 90%, red, transparent),
    radial-gradient(4px 4px at 5% 35%,  red, transparent),
    radial-gradient(4px 4px at 15% 75%, red, transparent),
    radial-gradient(4px 4px at 25% 5%,  red, transparent),
    radial-gradient(4px 4px at 35% 45%, red, transparent),
    radial-gradient(4px 4px at 45% 85%, red, transparent),
    radial-gradient(4px 4px at 55% 25%, red, transparent),
    radial-gradient(4px 4px at 65% 95%, red, transparent),
    radial-gradient(4px 4px at 75% 65%, red, transparent),
    radial-gradient(4px 4px at 85% 15%, red, transparent),
    radial-gradient(4px 4px at 95% 55%, red, transparent),
    radial-gradient(4px 4px at 100% 25%, #ff3333, transparent);
    animation: snow 8s linear infinite;
  }
}

.christmas-body::after, .valentines-body::after {
  content: "";
  position: fixed;
  top: 0;
  /* Start offscreen */
  left: 0;
  width: 100vw;
  height: 100%;
  background: radial-gradient(4px 4px at 2% 10%, var(--valentines), transparent),
  radial-gradient(4px 4px at 10% 50%, red, transparent),
  radial-gradient(4px 4px at 15% 30%, red, transparent),
  radial-gradient(4px 4px at 20% 60%, red, transparent),
  radial-gradient(4px 4px at 25% 80%, red, transparent),
  radial-gradient(4px 4px at 30% 40%, red, transparent),
  radial-gradient(4px 4px at 35% 10%, red, transparent),
  radial-gradient(4px 4px at 40% 70%, red, transparent),
  radial-gradient(4px 4px at 45% 20%, red, transparent),
  radial-gradient(4px 4px at 50% 90%, red, transparent),
  radial-gradient(4px 4px at 55% 50%, red, transparent),
  radial-gradient(4px 4px at 60% 30%, red, transparent),
  radial-gradient(4px 4px at 65% 60%, red, transparent),
  radial-gradient(4px 4px at 70% 80%, red, transparent),
  radial-gradient(4px 4px at 75% 40%, red, transparent),
  radial-gradient(4px 4px at 80% 10%, red, transparent),
  radial-gradient(4px 4px at 85% 70%, red, transparent),
  radial-gradient(4px 4px at 90% 50%, red, transparent),
  radial-gradient(4px 4px at 95% 90%, red, transparent),
  radial-gradient(4px 4px at 5% 35%,  red, transparent),
  radial-gradient(4px 4px at 15% 75%, red, transparent),
  radial-gradient(4px 4px at 25% 5%,  red, transparent),
  radial-gradient(4px 4px at 35% 45%, red, transparent),
  radial-gradient(4px 4px at 45% 85%, red, transparent),
  radial-gradient(4px 4px at 55% 25%, red, transparent),
  radial-gradient(4px 4px at 65% 95%, red, transparent),
  radial-gradient(4px 4px at 75% 65%, red, transparent),
  radial-gradient(4px 4px at 85% 15%, red, transparent),
  radial-gradient(4px 4px at 95% 55%, red, transparent),
  radial-gradient(4px 4px at 100% 25%,red, transparent);

  animation: snow2 12s linear infinite;
  z-index: 0;
}

.body-shop {
  padding: 100px;
  min-height: 90vh;

  background: var(--valentines);
}

.pagination {
  margin-top: 2em;

  .pagination-button {
    background: #da3a00;
    color: white;
    padding: .5em 1em;
    cursor: pointer;
    transition: var(--duration);

    &:hover {
      transform: scale(1.02);
    }
  }
}

.filter-container {
  margin-top: 85px;
  /* sacar despues de las fiestas! */
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: start !important;
  height: 44px;
  width: 70%;
}

.cards-container {
  gap: 1em;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
}

.filter__widget select {
  margin: 0 10px;
  padding: 10px 20px;
  border: 1px solid #bbbbbb;
  cursor: pointer;
  border-radius: 5px;
  background: #fbfbfb;
  color: #000;

  margin-bottom: 8px !important;
}

.filter__widget select:focus {
  outline: none !important;
}

.filter__widget select option {
  font-size: 0.9rem;
}

.search__box {
  align-items: end;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: pointer;
  width: 300px !important;
  height: 45px;

  padding: 0 !important;

  z-index: 200;
  background: white;

  margin-top: 1em;

  position: -webkit-sticky;
  /* Para Safari */
  position: sticky;
  top: 200px;


  & > form {
    height: 100%;
    position: relative;

    & > input {
      border-radius: 5px;
      z-index: 300;
      position: relative;
    }

    & > span {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.search-input {
  width: 100%;
  border: none !important;
  outline: none !important;
  padding: 11px 10px;
}

.search__box input::placeholder {
  width: 100%;
  color: #000;
}

.search__box span {
  width: 100%;
  color: #000;
}

.search-icon {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.button-container {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  pointer-events: none;
  z-index: 300;
}

.btn-wsp {
  z-index: 1001;
  cursor: pointer;
  height: 70px;
  width: 70px;
  pointer-events: all;
}

.vermas {
  display: flex;
  justify-content: center;
  width: 140px;
  margin: 50px 0;
  background-color: dodgerblue;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.vermas:hover {
  background-color: deepskyblue;
}

@media only screen and (max-width: 1700px) {
  .cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 1500px) {
  .cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 1210px) {
  .cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 975px) {
  .filter-container {
    flex-direction: column;
    margin-bottom: 4em;
    margin-top: 3em;
  }

  /*

  .filter__widget {
    margin: 10px 0;
  }

  .filter__widget select {
    margin-left: 0px;
  }

  */

  .search__box {
    width: 100%;
    padding-bottom: 12px;
  }

  .cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  /*
  .filter-container {
    margin-top: 50px;
  }

  .filter__widget select {
    padding: 7px 20px;
    font-size: 0.9rem;
    margin-left: 0px;
  }

  */

  .button-container {
    margin-right: 0px;
  }

  .btn-wsp {
    height: 45px;
    width: 45px;
  }
}

@media only screen and (max-width: 670px) {
  .search__box {
    top: 20%;
  }

  .filter-container {
    margin-top: 6em;
  }

  .body-shop {
    padding: 10px;
  }

  .cards-container {
    gap: 1em;
    display: flex;
    flex-wrap: wrap;
  }

  .card_a {
    max-width: 300px;
  }
}

@media only screen and (max-width: 430px) {
  /*
  .filter-container {
  }
  .filter__widget select {
    margin-left: 0px !important;
  }
  */
}

@media only screen and (max-width: 370px) {
  .cards-container {
    gap: .5em;
  }

  /*
  .filter__widget select {
    padding: 5px 20px;
  }
  */
}

@keyframes snow {
  from {
    transform: translateY(-50vh);
  }

  to {
    transform: translateY(100vh);
  }
}

@keyframes snow2 {
  from {
    transform: translateY(-100vh);
  }

  to {
    transform: translateY(100vh);
  }
}

@keyframes santa {
  0% {
    transform: translateX(-100%) scale(-1, 1);
  }

  80% {
    transform: translateX(0%) scale(-1, 1);
  }

  100% {
    transform: translateX(-100%) scale(-1, 1);
  }
}

@keyframes retraso {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.pag-text {
  color: white;
}
</style>
