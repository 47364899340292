<script setup>
import ProductCard from '../components/ProductCard.vue';
import Bulbs from '@/components/UI/Bulbs.vue';

import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();
const categorias = ref([]);
const params = ref([]);

const products = ref(null);
const showProducts = ref([]);

const currentPage = ref(1);
const lastPage = ref(1);

const categoria = ref(0);
const orderDir = ref("ASC");
const nombre = ref("");
const codigo = ref("");

const route = useRoute();
onMounted(async () => {
  let res = await store.dispatch("get", {
    tableName: "categorias",
    perPage: 100,
    page: 1
  })

  categorias.value = res?.data?.data?.map((x) => {
    x.categoria = x?.categoria?.split("\r\n")?.[0]?.toLowerCase();
    x.categoria = x?.categoria?.charAt(0).toUpperCase() + x?.categoria?.slice(1);
    return x;
  }).sort((a, b) => {
    if (a.categoria < b.categoria) return -1;
    if (a.categoria > b.categoria) return 1;
    return 0;
  });;

  if (route.query?.search) {
    categoria.value = route.query?.categoria;
    nombre.value = route.query?.nombre;
    codigo.value = route.query?.codigo;
    orderDir.value = route.query?.dir ?? "ASC";
  }
  await get();
})

const get = async () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });


  //showProducts.value = [];
  products.value = await store.dispatch("getProducts", {
    page: currentPage.value,
    categoria: (categoria.value && categoria.value != 0) ? categoria.value : null,
    nombre: nombre.value ?? null,
    codigo: codigo.value ?? null,
    order: "nombre",
    direction: orderDir.value
  })

  lastPage.value = products.value?.last_page;
  // showProducts.value = (showProducts.value.length === 0) ? products.value?.data : [...showProducts.value, ...products.value?.data];
  showProducts.value = products.value?.data;
};

const previous = async () => {

  if (currentPage.value <= 1) return;
  console.log('esta es la pagina', currentPage.value);
  currentPage.value--;
  await get();
}

const next = async () => {
  if (currentPage.value >= lastPage.value) return;
  console.log('esta es la pagina', lastPage.value);
  currentPage.value++;
  await get();
}

const handleChange = () => {
  let path = "?search=true";
  if (categoria.value && categoria.value !== 0) path += `&categoria=${categoria.value}`;
  if (nombre.value) path += `&nombre=${nombre.value}`;
  if (orderDir.value) path += `&dir=${orderDir.value}`;
  location.href = path;
}
</script>


<template>
  <body class='body-shop'>
    <div class="body-shop-container">
      <!--<div class="bulbs-c">
        <Bulbs />
      </div>-->
      <div class='filter-container'>
        <div class="filter__widget">
          <select @change="handleChange" v-model="categoria"
            aria-label="Orden por Categoria La Fortaleza Distribuidora">
            <option value="0">Filtrar por Categoria</option>
            <option v-for="(categoria, key) in categorias" :key="key" :value="categoria?.categoria">{{
              categoria?.categoria }}</option>
          </select>
        </div>
        <div class="filter__widget">
          <select @change="handleChange" v-model="orderDir" aria-label="Orden alfabético La Fortaleza Distribuidora">
            <option value="">Filtrar alfabéticamente</option>
            <option value="ASC">Filtrar A-Z</option>
            <option value="DESC">Filtrar Z-A</option>
          </select>
        </div>
      </div>

      <div class="search__box">
        <form @submit.prevent="handleChange">
          <input v-model="nombre" class='search-input' type="text" placeholder='Buscar...' />
          <span class='search-icon'>
            <ion-icon name="search-outline"></ion-icon>
          </span>
        </form>
      </div>
      <div class="pagination" flex gapped>
        <a class="pagination-button" radius-border @click="previous" aria-label="Página Anterior">Anterior</a>
        <p>Pág: {{ currentPage }} de {{ lastPage }}</p>
        <a class="pagination-button" radius-border @click="next" aria-label="Página Siguiente">Siguiente</a>
      </div>

      <div class='cards-container'>
        <ProductCard v-for="(product, key) in showProducts" :key="key" :item="product" />
      </div>

      <div class="pagination" flex gapped>
        <a class="pagination-button" radius-border @click="previous" aria-label="Página Anterior">Anterior</a>
        <p>Pág: {{ currentPage }} de {{ lastPage }}</p>
        <a class="pagination-button" radius-border @click="next" aria-label="Página Siguiente">Siguiente</a>
      </div>

      <div class='button-container' v-if="showProducts?.length > 0">
        <a href="https://api.whatsapp.com/send?phone=541128347514&text=&source=&data=&app_absent=" target='blank'>
          <img class='btn-wsp' src="../../public/images/whatsapp-sticky.png" title="Contáctanos por WhatsApp"
            alt="Clickea aquí para contactarnos" />
        </a>
      </div>
    </div>
  </body>
</template>


<style scoped>

select {
  margin: 0 !important;
  margin-right: 5px !important;
}

.body-shop {
  padding: 100px;
  min-height: 90vh;
}

.pagination {
  margin-top: 2em;

  .pagination-button {
    background: #da3a00;
    color: white;
    padding: .5em 1em;
    cursor: pointer;
    transition: var(--duration);

    &:hover {
      transform: scale(1.02);
    }
  }
}

.filter-container {
  /*margin-top: 85px; sacar despues de las fiestas! */
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: start !important;
  height: 44px;
  width: 70%;
}


.cards-container {
  gap: 1em;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
}

.filter__widget select {
  margin: 0 10px;
  padding: 10px 20px;
  border: 1px solid #bbbbbb;
  cursor: pointer;
  border-radius: 5px;
  background: #fbfbfb;
  color: #000;

  margin-bottom: 8px !important;
}

.filter__widget select:focus {
  outline: none !important;
}

.filter__widget select option {
  font-size: 0.9rem;
}

.search__box {
  align-items: end;
  justify-content: space-between;
  border: 1px solid #666;
  border-radius: 5px;
  cursor: pointer;
  width: 300px !important;
  height: 45px;

  padding: 0 !important;

  z-index: 200;
  background: white;

  margin-top: 1em;

  position: -webkit-sticky;
  /* Para Safari */
  position: sticky;
  top: 170px;



  &>form {
    height: 100%;
    position: relative;

    &>input {
      border-radius: 5px;
      z-index: 300;
      position: relative;
    }

    &>span {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.search-input {
  width: 100%;
  border: none !important;
  outline: none !important;
  padding: 11px 10px;
}

.search__box input::placeholder {
  width: 100%;
  color: #000;
}

.search__box span {
  width: 100%;
  color: #000;
}

.search-icon {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.button-container {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  pointer-events: none;
}

.btn-wsp {
  z-index: 1001;
  cursor: pointer;
  height: 70px;
  width: 70px;
  pointer-events: all;
}

.vermas {
  display: flex;
  justify-content: center;
  width: 140px;
  margin: 50px 0;
  background-color: dodgerblue;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.vermas:hover {
  background-color: deepskyblue;
}

@media only screen and (max-width: 1700px) {
  .cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 1500px) {
  .cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 1210px) {
  .cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 975px) {
  .filter-container {
    flex-direction: column;
    margin-bottom: 4em;
    margin-top: 3em;
  }

  /*

  .filter__widget {
    margin: 10px 0;
  }

  .filter__widget select {
    margin-left: 0px;
  }

  */

  .search__box {
    width: 100%;
    padding-bottom: 12px;
  }

  .cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  /*
  .filter-container {
    margin-top: 50px;
  }

  .filter__widget select {
    padding: 7px 20px;
    font-size: 0.9rem;
    margin-left: 0px;
  }

  */

  .button-container {
    margin-right: 0px;
  }

  .btn-wsp {
    height: 45px;
    width: 45px;
  }
}

@media only screen and (max-width: 670px) {
  .search__box {
    top: 25%;
  }

  .filter-container {
    margin-top: 6em;
  }

  .body-shop {
    padding: 10px;
  }

  .cards-container {
    gap: 1em;
    display: flex;
    flex-wrap: wrap;
  }

  .card_a {
    max-width: 300px;
  }
}

@media only screen and (max-width: 430px) {
  /*
  .filter-container {
  }
  .filter__widget select {
    margin-left: 0px !important;
  }
  */
}

@media only screen and (max-width: 370px) {
  .cards-container {
    gap: .5em;
  }

  /*
  .filter__widget select {
    padding: 5px 20px;
  }
  */
}
</style>
