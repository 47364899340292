<script setup>
import {Swiper} from 'swiper';
import {Navigation} from "swiper/modules";
import {useRoute} from 'vue-router';
import {onMounted, reactive, ref} from 'vue';
import {useStore} from 'vuex';
import {backendUrl, post} from '../../services/apiReq';
import {imageHandler} from '../../services/errors';

import 'swiper/swiper-bundle.css';
import axios from "axios";

Swiper.use([Navigation]);

const route = useRoute();
const store = useStore();
const item = reactive({});
const cantidad = ref(1);
const observacion = ref("");
const itemImages = ref([]);


const carousel = reactive({});

onMounted(async () => {
  let res = await store.dispatch("get", {
    tableName: "products",
    perPage: 1,
    page: 1,
    search: {
      strict: true,
      param: "codigo",
      value: route?.query?.producto
    }
  });
  item.value = res?.data?.data?.[0];
  if (!item.value) return;

  await prepareCarousel();

  res = await store.dispatch("get", {
    tableName: "ofertas",
    perPage: 1,
    page: 1,
    search: {
      strict: true,
      param: "codigo_nombre",
      value: route?.query?.producto
    }
  });

  if (!res) return;
  let info = res?.data?.data?.[0];


  if (!info) return;
  item.value = {
    ...item.value,
    ...info
  }
  item.value.precio_final = (item.value.precio - (item.value.descuento * item.value.precio) / 100)?.toFixed(2);
});

const add = async () => {
  if (!store.getters.getUser?.id) location.href = "/login";
  let res = await post("/carrito", {
    name: "carritos",
    user_id: store.getters.getUser?.id,
    cantidad: cantidad.value,
    producto_codigo: item?.value?.codigo,
    observacion: observacion.value
  });
};

const increment = () => {
  cantidad.value++;
};

const decrement = () => {
  cantidad.value = cantidad.value > 1 ? cantidad.value - 1 : cantidad.value;
};

const prepareCarousel = async () => {

  let images = await post("/files/name", {
    name: item.value.codigo.trim()
  })

  itemImages.value = images?.data;
  setTimeout(() => {

    carousel.value = new Swiper('.swiper-container',
        {
          navigation: {
            nextEl: ".swiper-button-next-custom",
            prevEl: ".swiper-button-prev-custom",
          },
        }
    );
  }, 0);
}
</script>


<template>
  <div v-if="Object.keys(item).length" class="itemdetail__container">
    <div class="product__info">
      <h2 class="product__title">{{ item.value.nombre }}</h2>
      <div v-if="item?.value?.frio?.length > 0"  radius-border class="orange" slide>
        <h4>Necesita cadena de frio</h4>
      </div>
      <div v-if="!item.value.descuento && store.getters.getUser?.id">
        <h3 class="product__price">AR$ {{ item.value.precio }}</h3>
      </div>
      <div v-if="item.value.descuento && store.getters.getUser?.id">
        <h3 class="discount product__price">
          <span class="discount-price">${{ item.value.precio }}</span> %{{ item.value.descuento }} OFF
        </h3>
        <h3 class="product__price">AR$ {{ item.value.precio_final }}</h3>
      </div>
      <br/>
      <div v-if="!store.getters.getUser?.id">
        <a href="/login" class="orangered">Inicie sesión para ver más detalles</a>
      </div>
      <div class="quantity__control">
        <!--<h3 class='product__stock'>Stock : {{ stock }}</h3>-->
        <div class="input__container">
          <button class="qty-btn" @click="decrement">-</button>
          <input class="qty-input" type="number" v-model="cantidad" readonly/>
          <button class="qty-btn" @click="increment">+</button>
        </div>
      </div>
      <button class="add-btn" @click="add" v-if="!item?.value?.stock">Agregar al Carrito</button>
      <p v-else>Sin stock disponible</p>
      <textarea placeholder="Observaciones" id="observacion" v-model="observacion"></textarea>
    </div>
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(image, index) in itemImages" :key="index">
          <img :title="item ? item.value.nombre : ''" :src="`${backendUrl}/files/2/${image}`"
               @error="imageHandler($event)" alt="La Fortaleza Producto"/>
        </div>
        <div v-if="(itemImages?.length <= 0)" class="swiper-slide">
          <img :title="item ? item.value.nombre : ''" :src="`${backendUrl}/files/noimage.png`" alt="La Fortaleza Producto"/>
        </div>

      </div>
      <div class="swiper-button-next-custom">&gt;</div>
      <div class="swiper-button-prev-custom">&lt;</div>
    </div>
  </div>
  <div v-else class="itemdetail__container">
    <div class="product__info loading">
      <h2 class="product__title"></h2>
      <br/>
      <h3 class="product__price"></h3>
      <br/>
    </div>
    <div class="product__image loading">
    </div>
  </div>
</template>


<style scoped>
.orange {
  background: var(--primary-orange);
  border: none;
  padding: .3em;
  color: white;
  margin-bottom: .5em;
}
.discount {
  color: gray !important;
  font-size: .9em !important;
}

.discount-price {
  text-decoration: line-through;
}

.itemdetail__container {
  padding: 120px 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.product__info {
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4em;
}

.product__title {
  font-style: italic;
}

.product__price {
  font-size: 20px;
  color: #08A208;
  font-weight: 600;
}

.quantity__control {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  margin: 20px 0;
}

.input__container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.quantity__control .qty-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  width: 20px;
  height: 22px;
  font-size: 18px;
  cursor: pointer;
}

.quantity__control .qty-input {
  margin: 0 6px;
  width: 70px;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #8d8d8d;
}

.add-btn {
  cursor: pointer;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 14px;
  font-size: 14px;
  font-weight: 600;
  transition: all .5s ease-in-out;
}

.add-btn:hover {
  background-color: #F8853E;
  color: #fff;
}

/* Estilos para el carrusel */
.swiper-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;

  position: relative;
  margin-left: 2em;
}

.swiper-slide {
  width: 100%;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Centrar la imagen verticalmente */
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide img {
  max-width: 100%;
  height: auto;
  transition: all .3s ease;
  border-radius: 4px;
}

.swiper-slide img:hover {
  transform: scale(1.02);
}

.swiper-button-next-custom,
.swiper-button-prev-custom {
  position: absolute;
  width: 35px;
  height: 35px;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  border: #E72F39 solid 1.5px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
}

.swiper-button-next-custom:before,
.swiper-button-prev-custom:before {
  font-size: 20px;
}

.swiper-button-next-custom {
  right: 10px;
}

.swiper-button-prev-custom {
  left: 10px;
}

.loading {
  margin: 0 .5em;
  height: 600px;
  width: 100%;
  background: rgba(128, 128, 128, 0.43);
}

#observacion {
  height: 150px;
  width: 350px;
  resize: none;
  margin-top: 2em;
  border-radius: 10px;
  padding: 1em;

  &:focus {
    outline: none;
  }
}

.orangered {
  color: orangered;
}

@media only screen and (max-width: 1388px) {
  .itemdetail__container {
    padding: 100px 60px;
  }

  .swiper-container {
    width: 80%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 968px) {
  .itemdetail__container {
    padding: 100px 60px;
    flex-direction: column;
    justify-content: space-between;
  }

  .swiper-container {
    width: 80%;
    margin: 20px auto;
  }
}

@media only screen and (max-width: 568px) {
  .itemdetail__container {
    padding: 60px 12px;
  }

  .swiper-container {
    width: 95%;
    margin: 0 auto;
  }

  #observacion {
    width: 100%;
    max-width: 320px;
    margin-bottom: 25px;
  }
}
</style>
