import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import BuyOnline from '@/views/BuyOnline.vue'
import ContactView from '@/views/ContactView.vue'
import Login from '@/views/Login.vue'
import Signup from '@/views/Signup.vue'

import ItemDetail from '@/views/ItemDetail.vue'
import CartView from '@/views/CartView.vue'
import Shopping from '@/views/Shopping.vue'
import Checkout from '@/views/Checkout.vue'
import Shipment from '@/views/Shipment.vue'
import Ticket from '@/views/Ticket.vue'

import Carousel from '@/components/Carousel.vue'
import BuyOnlineEspecial from '@/views/BuyOnlineEspecial.vue'
import FormularioSorteo from "@/views/FormularioSorteo.vue";
import table_form from "@/views/TableSorteo.vue";
import TableSorteo from "@/views/TableSorteo.vue";

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: HomeView
  },
  {
    path: '/tienda',
    name: 'Tienda',
    component: BuyOnline
  },
  {
    path: '/especial',
    name: 'Especial',
    component: BuyOnlineEspecial
  },
  {
    path: '/nosotros',
    name: 'Nosotros',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: ContactView
  },
  {
    path: '/login',
    name: 'Iniciar Sesion',
    component: Login
  },
  {
    path: '/signup',
    name: 'Registrarse',
    component: Signup
  },
  {
    path: '/itemdetail',
    name: 'Producto',
    component: ItemDetail
  },
  {
    path: '/carrito',
    name: 'Mi carrito',
    component: CartView
  },
  {
    path: '/compras',
    name: 'Compras',
    component: Shopping
  },
  {
    path: '/verificacion',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/verificacion/envio',
    name: 'Envio',
    component: Shipment
  },
  {
    path: '/verificacion/envio/ticket',
    name: 'Ticket',
    component: Ticket
  },
  {
    path: '/formulario/sorteo',
    name: 'FSorteo',
    component: FormularioSorteo
  },
  {path: '/formulario/table',
  name: 'TableSorteo',
  component: TableSorteo
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `La Fortaleza - ${to?.name}`;
  next();
});

export default router
